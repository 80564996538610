<template>
  <div>
    <CRow ref="homeTiles">
      <CCol title="corporate" v-if="isAvailable('corporate')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/corporate" class="stretched-link">
                <i class="fa fa-briefcase fa-2xl"></i>
                Corporate
              </a>
            </h2>
            <ul>
              <li>Product Catalog Tool</li>
              <li>Executive Retail Report</li>
              <li>KPI Dashboard</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="cultivation" v-if="isAvailable('cultivation')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/cultivation" class="stretched-link">
                <CIcon name="cil-plant" size="xl"></CIcon>
                Cultivation
              </a>
            </h2>
            <ul>
              <li>Plant Inventory</li>
              <li>Inventory Reconciliation</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="distribution" v-if="isAvailable('distribution')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/distribution" class="stretched-link">
                <i class="fa fa-truck fa-2xl"></i>
                Distribution
              </a>
            </h2>
            <ul>
              <li>View Orders</li>
              <li>Schedule Deliveries</li>
              <li>Manage Transporters</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="harvest" v-if="isAvailable('harvest')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/harvest" class="stretched-link">
                <CIcon name="cil-recycle" size="xl"></CIcon>
                Harvest
              </a>
            </h2>
            <ul>
              <li>Harvest Batch Reporting</li>
              <li>Harvest Day Forms</li>
              <li>Harvest Day Reporting</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="lab" v-if="isAvailable('lab')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/lab" class="stretched-link">
                <CIcon name="cil-beaker" size="xl"></CIcon>
                Lab
              </a>
            </h2>
            <ul>
              <li>Batch Forms</li>
              <li>Batch Reporting</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="post-harvest" v-if="isAvailable('post-harvest')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/post-harvest" class="stretched-link">
                <CIcon name="cil-flower" size="xl"></CIcon>
                Post Harvest
              </a>
            </h2>
            <ul>
              <li>Post Harvest Forms</li>
              <li>Post Harvest Reporting</li>
              <li>Post Harvest Employee Management</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="retail" v-if="isAvailable('retail')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/retail" class="stretched-link">
                <i class="fa fa-shopping-basket fa-2xl"></i>
                Retail
              </a>
            </h2>
            <ul>
              <li>Inventory</li>
              <li>Liberty Wallet</li>
              <li>Daily Sales Plan</li>
              <li>Curbside Pickup</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="production" v-if="isAvailable('lab')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/production" class="stretched-link">
                <i class="fa fa-industry fa-2xl"></i>
                Production
              </a>
            </h2>
            <ul>
              <li>Intermediate Flower Batches</li>
            </ul>
            <ul>
              <li>Intermediate Terpene Batches</li>
            </ul>
            <ul>
              <li>Swisher Infused Pre-Rolls</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="security" v-if="isAvailable('security')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/security" class="stretched-link">
                <i class="fa fa-shield fa-2xl"></i>
                Security
              </a>
            </h2>
            <ul>
              <li>Visitor Log</li>
              <li>Security Checklists</li>
              <li>Location Tools</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="support" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/support" class="stretched-link">
                <i class="fa fa-ticket fa-2xl"></i>
                Support
              </a>
            </h2>
            <ul>
              <li>Create Tickets</li>
              <li>View and Resolve Tickets</li>
              <li>Ticket Category Management</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="tech" v-if="isAvailable('tech')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/tech" class="stretched-link">
                <CIcon name="cil-devices" size="xl"></CIcon>
                Technology
              </a>
            </h2>
            <ul>
              <li>User Management</li>
              <li>Metrc Adjustments</li>
              <li>Location Tools</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol title="wholesale" v-if="isAvailable('wholesale')" class="mb-4 hi__appcard" :sm="6" :md="6" :lg="3" :xl="3">
        <CCard class="mb-4 h-100">
          <CCardBody>
            <h2>
              <a href="/wholesale" class="stretched-link">
                <CIcon name="cil-building" size="xl"></CIcon>
                Wholesale
              </a>
            </h2>
            <ul>
              <li>Weekly Sales Plan</li>
              <li>Inventory</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
  import Sentry from '@shared/services/Sentry'

  export default {
    name: 'Home',
    data () {
      return {
        availableApps: [],
        apps: [
          'corporate',
          'cultivation',
          'distribution',
          'harvest',
          'lab',
          'post-harvest',
          'retail',
          'security',
          'support',
          'tech',
          'wholesale',
        ],
      }
    },

    methods: {
      isAvailable(app) {
        return this.availableApps.includes(app)
      },
    },

    async mounted() {
      if (!this.$store.getters.authToken) {
        return this.$router.push('/login')
      }
      const loader = this.$loading.show()
      const user = await this.getUserProfile()
      const userGroups = user.groups || []

      this.availableApps = userGroups
        .map(g => g.group.name.toLowerCase())
        .filter(g => this.apps.includes(g))

      // Set user data in logger(s)
      try {
        Sentry.setUser({ id: user?.id, username: user?.username })
        this.$log.setUser(user)
      } catch (e) {
        this.$log.error('Error setting user in Sentry/Mezmo', e)
      }

      loader.hide()
    },
  }
</script>

<style scoped>
.hi__appcard {
  min-height: 175px;
}

.hi__appcard .card-body {
  padding: 1.25rem 1.25rem 0.25rem 1.25rem;
}

.hi__appcard ul {
  margin-bottom: 0;
}

.card-body:hover {
  background-color: rgba(120, 120, 120, 0.1);
}
</style>
